<template>
  <div>
    <div>
      <b-loading
        v-model="loading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <div class="level">
        <div class="level-left">
          <b-field expanded>
            <b-input
              size="is-small"
              placeholder="Search..."
              icon="search"
              @input="searchLead()"
            />
          </b-field>
        </div>

        <div class="level-right">
          <div>
            <slot name="re-assign-button" />
          </div>

          <div>
            <slot name="open-switch-button" />
          </div>

          <div>
            <b-button
              v-if="isEditing"
              ref="saveEntryButton"
              size="is-small"
              class="mr-4"
              icon-left="save"
              type="is-success"
              @click="updateLead"
            >
              save
            </b-button>
            <b-button
              v-if="isEditing"
              ref="cancelEntryButton"
              size="is-small"
              icon-left="close"
              type="is-success"
              @click="cancelUpdate"
            >
              cancel
            </b-button>
          </div>

          <div>
            <b-button
              v-if="Object.keys(filters).length > 0"
              size="is-small"
              icon="power-off"
              @click="resetFilters"
            >
              Reset Filters
            </b-button>
          </div>

          <div>
            <b-dropdown
              aria-role="list"
              postition="is-bottom-left"
              :scrollable="true"
              :max-height="300"
              :close-on-click="false"
              class="dg-columns"
            >
              <b-button
                slot="trigger"
                icon-left="eye"
                size="is-small"
                icon-right="chevron-down"
              >
                columns
              </b-button>
              <draggable
                v-model="columns"
                group="columns"
                handle=".handle"
                @start="drag=true"
                @end="drag=false"
              >
                <b-dropdown-item
                  v-for="column in columns"
                  :key="column.id"
                  aria-role="listitem"
                >
                  <b-field>
                    <b-checkbox
                      :id="column.field"
                      v-model="column.visible"
                      size="is-small"
                    >
                      {{
                        column.label.startsWith('custom')
                          ? getCustomFieldLabel(column.label, 'Lead_Opportunity') : column.label
                      }}
                    </b-checkbox>
                    <i class="grip-vertical handle" />
                  </b-field>
                </b-dropdown-item>
              </draggable>
              <hr class="dropdown-divider">
            </b-dropdown>
          </div>

          <div>
            <b-dropdown
              aria-role="list"
              postition="is-bottom-left"
            >
              <b-button
                slot="trigger"
                size="is-small"
                icon-left="wrench"
                icon-right="chevron-down"
              >
                grids
              </b-button>

              <b-dropdown-item aria-role="listitem">
                <b-field>
                  <b-checkbox
                    v-model="customizations.isBordered"
                    size="is-small"
                  >
                    Bordered
                  </b-checkbox>
                </b-field>
              </b-dropdown-item>
              <hr class="dropdown-divider">

              <b-dropdown-item aria-role="listitem">
                <b-field>
                  <b-checkbox
                    v-model="customizations.isCheckable"
                    size="is-small"
                  >
                    Checkable
                  </b-checkbox>
                </b-field>
              </b-dropdown-item>
              <hr class="dropdown-divider">

              <b-dropdown-item aria-role="listitem">
                <b-field>
                  <b-checkbox
                    v-model="customizations.isNarrowed"
                    size="is-small"
                  >
                    Narrowed
                  </b-checkbox>
                </b-field>
              </b-dropdown-item>
              <hr class="dropdown-divider">

              <b-dropdown-item aria-role="listitem">
                <b-field>
                  <b-checkbox
                    v-model="customizations.isStickyHeaders"
                    size="is-small"
                  >
                    Sticky headers
                  </b-checkbox>
                </b-field>
              </b-dropdown-item>
              <hr class="dropdown-divider">

              <b-dropdown-item aria-role="listitem">
                <b-field>
                  <b-checkbox
                    v-model="customizations.isScrollable"
                    size="is-small"
                  >
                    Scrollable
                  </b-checkbox>
                </b-field>
              </b-dropdown-item>

              <hr class="dropdown-divider">

              <b-dropdown-item aria-role="listitem">
                <b-field>
                  <b-checkbox
                    v-model="customizations.isStriped"
                    size="is-small"
                  >
                    Striped
                  </b-checkbox>
                </b-field>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <b-table
      ref="leadsTable"
      v-click-outside-dg="{
        handler: 'onClickOutsideTable',
        exclude: [
          'inlineEditingSavePrompt',
          'leadClosingReasonModal',
          'saveEntryButton',
          'cancelEntryButton',
        ],
      }"
      :mobile-cards="false"
      :loading="loading || isLoading"
      :data="leads.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      detailed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @details-open="(row) => fetchActivities(row.id)"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label.startsWith('custom')
          ? getCustomFieldLabel(column.label, 'Lead_Opportunity') : column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-input
              v-show="
                column.searchField === 'bidDueDate' ||
                  column.searchField === 'estInvoiceDate' ||
                  column.searchField === 'createdAt' ||
                  column.searchField === 'estInstallDate' ||
                  column.searchField === 'estBookingDate'"
              v-model="props.filters[column.searchField]"
              type="date"
              icon="calendar"
              size="is-small"
            />

            <b-select
              v-show="column.searchField === 'status'"
              v-model="props.filters[column.searchField]"
              size="is-small"
            >
              <option
                v-for="(stage, stageIndex) in leadStages"
                :key="stageIndex"
                :value="stage.value"
              >
                {{ stage.value.replace("Qualifed", "Contacted") }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'source'"
              v-model="props.filters[column.searchField]"
              size="is-small"
            >
              <option
                v-for="(source, sourceIndex) in leadSources"
                :key="sourceIndex"
                :value="source.value"
              >
                {{ source.value }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'temperature'"
              v-model="props.filters[column.searchField]"
              size="is-small"
            >
              <option
                v-for="(temperature, temperatureIndex) in temperatures"
                :key="temperatureIndex"
                :value="temperature.value"
              >
                {{ temperature.value }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'probability'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="option in probabilities"
                :key="option.id"
                :value="option.value"
              >
                {{ option.value }}
              </option>
            </b-select>

            <b-input
              v-show="
                column.searchField === 'estimatedRevenue' ||
                  column.searchField === 'expectedGp'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
              type="number"
            />

            <b-input
              v-show="
                column.searchField === 'name' ||
                  column.searchField === 'accountName' ||
                  column.searchField === 'contactName' ||
                  column.searchField === 'contactName' ||
                  column.searchField === 'owners' ||
                  column.searchField === 'estimatedGrossProfitPercent' ||
                  column.searchField === 'tags' ||
                  column.searchField === 'createdByName' ||
                  column.searchField === 'updatedByName' ||
                  column.searchField === 'customField1' ||
                  column.searchField === 'customField2' ||
                  column.searchField === 'customField3' ||
                  column.searchField === 'customField4' ||
                  column.searchField === 'customField5' ||
                  column.searchField === 'customField6' ||
                  column.searchField === 'customField7' ||
                  column.searchField === 'customField8' ||
                  column.searchField === 'customField9' ||
                  column.searchField === 'customField10'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div
              v-if="props.row.status"
              class="buttons"
            >
              <b-tooltip
                v-if="props.row.status.startsWith('open')"
                type="is-info"
                label="Convert"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="bullseye"
                  @click="$emit('convert-to-opportunity', props.row.id)"
                />
              </b-tooltip>

              <b-tooltip
                v-if="props.row.status.startsWith('open')"
                type="is-info"
                label="Add Activity"
                position="is-right"
              >
                <b-dropdown
                  aria-role="list"
                  append-to-body
                >
                  <b-button
                    slot="trigger"
                    type="is-text"
                    class="has-text-grey"
                    icon-right="plus"
                  />
                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 165)"
                  >
                    <b-icon icon="stopwatch" />
                    <span class="has-text-weight-semibold">Task</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">

                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 164)"
                  >
                    <b-icon icon="sticky-note" />
                    <span class="has-text-weight-semibold">Note</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">

                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 166)"
                  >
                    <b-icon icon="calendar" />
                    <span class="has-text-weight-semibold">Event</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Edit"
                position="is-right"
              >
                <b-button
                  tag="router-link"
                  :to="{ name: 'EditLead', params: { leadId: props.row.id } }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="edit"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Email"
                position="is-right"
              >
                <b-button
                  v-if="props.row.contactName"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="envelope"
                  @click="
                    sendMail(
                      props.row.contactEmail,
                      props.row.name
                    )
                  "
                />
              </b-tooltip>
            </div>
          </template>

          <template v-else-if="column.field === 'name'">
            <router-link
              class="has-text-primary has-text-weight-bold"
              :to="{ name: 'ViewLead', params: { leadId: props.row.id } }"
            >
              {{ props.row[column.field] }}
            </router-link>
          </template>

          <b-tooltip
            v-else
            label="Double-click to edit"
            class="is-info"
          >
            <template v-if="column.field === 'accountName'">
              <span
                v-if="props.row.accountName"
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >{{ props.row.accountName }}</span>
            </template>

            <template v-else-if="column.field === 'contactName'">
              <span
                v-if="props.row.contactName"
                class="has-text-info has-text-weight-bold is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.contactName | capitalize }}
              </span>
            </template>

            <template v-else-if="column.field === 'owners'">
              <template v-if="props.row[column.field]">
                <template
                  v-for="(currenOwner, ownerIndex) in props.row.owners.split(',')"
                >
                  <template v-if="currenOwner">
                    <b-taglist
                      :key="ownerIndex"
                      attached
                    >
                      <b-tag>
                        {{ currenOwner.split("/")[0] }}
                      </b-tag>
                      <b-tag type="is-info">
                        {{ currenOwner.split("/")[1] }} %
                      </b-tag>
                    </b-taglist>
                  </template>
                </template>
              </template>
            </template>

            <template v-else-if="column.field === 'status'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-select
                  v-model="stageId"
                  size="is-small"
                >
                  <option
                    v-for="(stage, stageIndex) in leadStages"
                    :key="stageIndex"
                    :value="stage.id"
                  >
                    {{ stage.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.status.replace('Qualifed', 'Contacted') }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'source'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-select
                  v-model="leadsSourceId"
                  size="is-small"
                >
                  <option
                    v-for="(source, sourceIndex) in leadSources"
                    :key="sourceIndex"
                    :value="source.id"
                  >
                    {{ source.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.source }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'temperature'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-select
                  v-model="temperatureId"
                  size="is-small"
                >
                  <option
                    v-for="(temperature, temperatureIndex) in temperatures"
                    :key="temperatureIndex"
                    :value="temperature.id"
                  >
                    {{ temperature.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'estimatedRevenue'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <currency-input
                  v-model="estimatedRevenue"
                  class="input is-small"
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] | currency }}
              </span>
            </template>

            <template v-else-if="column.field === 'expectedGp'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <currency-input
                  v-model="estimatedGrossProfit"
                  class="input is-small"
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.expectedGp | currency }}
              </span>
            </template>

            <template v-else-if="column.field === 'estimatedGrossProfitPercent'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-input
                  v-model="estGrossProfitPercentage"
                  size="is-small"
                  @input="setGrossProfit"
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template v-if="props.row.estimatedGrossProfitPercent">
                  {{ props.row.estimatedGrossProfitPercent }} %
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'probability'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-select
                  v-model="probability"
                  size="is-small"
                >
                  <option
                    v-for="option in probabilities"
                    :key="option.id"
                    :value="option.code"
                  >
                    {{ option.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row.probability"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ (props.row.probability / 100) | percent }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'bidDueDate'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-input
                  v-model="bidDueDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template v-if="props.row.bidDueDate">
                  <b-tag
                    v-if="colorCoding.BIDDUEDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.bidDueDate,
                        colorCoding.BIDDUEDATE.days,
                        'BIDDUEDATE'
                      ),
                    }"
                  >
                    {{ props.row.bidDueDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="bordercolor: transparent"
                  >
                    {{ props.row.bidDueDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'estBookingDate'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-input
                  v-model="estBookingDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template
                  v-if="props.row.estBookingDate"
                >
                  <b-tag
                    v-if="colorCoding.ESTBOOKINGDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.estBookingDate,
                        colorCoding.ESTBOOKINGDATE.days,
                        'ESTBOOKINGDATE'
                      ),
                    }"
                  >
                    {{ props.row.estBookingDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="bordercolor: transparent"
                  >
                    {{ props.row.estBookingDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'estInstallDate'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-input
                  v-model="estInstallDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template
                  v-if="props.row.estInstallDate"
                >
                  <b-tag
                    v-if="colorCoding.ESTINSTALLDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.estInstallDate,
                        colorCoding.ESTINSTALLDATE.days,
                        'ESTINSTALLDATE'
                      ),
                    }"
                  >
                    {{ props.row.estInstallDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="bordercolor: transparent"
                  >
                    {{ props.row.estInstallDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'estInvoiceDate'">
              <b-field v-if="isEditing && props.row.id === selectedLeadId">
                <b-input
                  v-model="estInvoiceDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template v-if="props.row.estInvoiceDate">
                  <b-tag
                    v-if="colorCoding.ESTINVOICEDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.estInvoiceDate,
                        colorCoding.ESTINVOICEDATE.days,
                        'ESTINVOICEDATE'
                      ),
                    }"
                  >
                    {{ props.row.estInvoiceDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="bordercolor: transparent"
                  >
                    {{ props.row.estInvoiceDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'tags'">
              <template v-if="props.row.tags">
                <b-tag
                  v-for="(tag, tagIndex) in props.row.tags.split(',')"
                  :key="tagIndex"
                >
                  {{ tag }}
                </b-tag>
              </template>
            </template>

            <template
              v-else-if="
                column.field === 'createdAt' || column.field === 'updatedAt'
              "
            >
              {{ props.row[column.field] | date }}
            </template>

            <template v-else-if="column.field.startsWith('custom')">
              <div
                v-if="isEditing && props.row.id === selectedLeadId
                  && customFieldsList.length > 0"
              >
                <validation-provider
                  v-for="(field, fieldIndex) in customFieldsList.filter(
                    (item) => item.label === getCustomFieldLabel(column.label, 'Lead_Opportunity'))"
                  :key="fieldIndex"
                  v-slot="{ errors }"
                  :rules="{required: field.isRequired}"
                  :name="field.label"
                >
                  <b-field
                    v-if="field.type === 'TEXT_INPUT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-input
                      v-model="props.row[column.field]"
                      size="is-small"
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                      )"
                    />
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'CURRENCY'"
                    horizontal
                    :label="field.label"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column">
                        <currency-input
                          v-model="props.row[column.field]"
                          size="is-small input"
                          expanded
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'DROP_DOWN'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-select
                      v-model="props.row[column.field]"
                      size="is-small"
                      expanded
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                      )"
                    >
                      <option
                        v-for="option in field.lookups"
                        :key="option.code"
                        :value="option.value"
                      >
                        {{ option.value }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'MULTI_SELECT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column is-half">
                        <b-taginput
                          v-model="props.row[column.field]"
                          v-multi-select-field="{
                            index: props.index,
                            field: column.field + 'Arr',
                            handler: 'setFormattedMultiSelectValues',
                          }"
                          :data="field.lookups.map(i => i.value)"
                          autocomplete
                          field="value"
                          type="is-info"
                          :open-on-focus="true"
                          placeholder="Select one or multiple"
                          @typing="(val) => getCustomFieldFilteredTags(val, field)"
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                </validation-provider>
              </div>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  <template
                    v-if="
                      customFieldsList.filter((item
                      ) => item.label === getCustomFieldLabel(column.label, 'Lead_Opportunity')
                      )[0].type === 'CURRENCY'
                    "
                  >
                    {{ props.row[column.field] | currency }}
                  </template>
                  <template v-else>
                    {{ props.row[column.field] }}
                  </template>
                </span>
              </template>
            </template>

            <template v-else>
              <template v-if="props.row[column.field]">
                {{ props.row[column.field] }}
              </template>
            </template>
          </b-tooltip>
        </template>
      </b-table-column>

      <template
        slot="detail"
        slot-scope="props"
      >
        <activities-minimal-table />

        <b-button
          size="is-small"
          tag="router-link"
          :to="{
            name: 'ViewLead',
            params: { leadId: props.row.id },
            query: { activeTab: 1 },
          }"
          type="is-info"
          icon-left="bell"
        >
          view all
        </b-button>
      </template>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                size="is-small"
                type="is-info"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="leads" />
      </template>
    </b-table>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="re-assign owners modal"
      aria-modal
    >
      <template #default="props">
        <reassign-modal-form
          modal-title="RE-ASSIGN OWNER"
          :route-name="routeName"
          v-bind="formProps"
          @close="props.close"
        />
      </template>
    </b-modal>

    <b-modal
      ref="leadClosingReasonModal"
      v-model="isleadClosingReasonModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="closing reason modal"
      aria-modal
    >
      <template #default="props">
        <lead-closing-reason-modal
          ref="closingReasonField"
          :opportunity-stages="leadStages"
          @close="props.close"
        />
      </template>
    </b-modal>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateLead"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import SearchMixin from '@/mixins/SearchMixin';

import {
  TableFooter, EmptyTable, ReassignModalForm, InlineEditingSavePrompt,
  LeadClosingReasonModal,
} from '@/components/Shared';
import { ActivitiesMinimalTable } from '@/components/Activities';
import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'LeadsTable',

  components: {
    TableFooter,
    EmptyTable,
    ActivitiesMinimalTable,
    draggable,
    ReassignModalForm,
    InlineEditingSavePrompt,
    LeadClosingReasonModal,
  },

  mixins: [SearchMixin],

  data: () => ({
    defaultImage,

    checkedRows: [],

    rowInEdit: {},

    isLoading: false,

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],
    defaultOpenedDetails: [],

    sortField: 'createdAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    openSwitch: true,

    isSavePromptActive: false,

    savePromptOpen: false,

    filters: {},

    exportFields: {},

    searchClause: '',

    isEditing: false,
    inEditingMode: false,
    selectedLeadId: null,

    isComponentModalActive: false,

    isleadClosingReasonModal: false,

    formProps: {
      dataIDList: [],
    },

    columns: [
      {
        id: '1',
        label: 'Lead Name',
        field: 'name',
        sortable: true,
        searchable: true,
        searchField: 'name',
        exportField: 'name',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Account',
        field: 'accountName',
        sortable: true,
        searchable: true,
        searchField: 'accountName',
        exportField: 'accountName',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Contact',
        field: 'contactName',
        sortable: true,
        searchable: true,
        searchField: 'contactName',
        exportField: 'contactName',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Status',
        field: 'status',
        sortable: true,
        searchable: true,
        searchField: 'status',
        exportField: 'status',
        sticky: false,
        visible: true,
      },
      {
        id: '5',
        label: 'Source',
        field: 'source',
        sortable: true,
        searchable: true,
        searchField: 'source',
        exportField: 'source',
        sticky: false,
        visible: false,
      },
      {
        id: '6',
        label: 'Temperature',
        field: 'temperature',
        sortable: true,
        searchable: true,
        searchField: 'temperature',
        exportField: 'temperature',
        sticky: false,
        visible: false,
      },
      {
        id: '7',
        label: 'Owner',
        field: 'owners',
        sortable: true,
        searchable: true,
        searchField: 'owners',
        exportField: 'owners',
        sticky: false,
        visible: true,
      },
      {
        id: '8',
        label: 'Estimated Revenue',
        field: 'estimatedRevenue',
        sortable: true,
        searchable: false,
        searchField: 'estimatedRevenue',
        exportField: 'estimatedRevenue',
        sticky: false,
        visible: false,
      },
      {
        id: '9',
        label: 'Tags',
        field: 'tags',
        sortable: true,
        searchable: true,
        searchField: 'tags',
        exportField: 'tags',
        sticky: false,
        visible: false,
      },
      {
        id: '10',
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: true,
        searchField: 'createdAt',
        exportField: 'createdAt',
        sticky: false,
        visible: false,
      },
      {
        id: '11',
        label: 'Created By',
        field: 'createdByName',
        sortable: true,
        searchable: true,
        searchField: 'createdByName',
        exportField: 'createdByName',
        sticky: false,
        visible: false,
      },
      {
        id: '12',
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: false,
        searchField: 'updatedAt',
        exportField: 'updatedAt',
        sticky: false,
        visible: false,
      },
      {
        id: '13',
        label: 'Modified By',
        field: 'updatedByName',
        sortable: true,
        searchable: true,
        searchField: 'updatedByName',
        exportField: 'updatedByName',
        sticky: false,
        visible: false,
      },
      {
        id: '14',
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
      {
        id: '25',
        label: 'Probability',
        field: 'probability',
        sortable: true,
        searchable: true,
        searchField: 'probability',
        exportField: 'probability',
        sticky: false,
        visible: true,
      },
      {
        id: '26',
        label: 'Estimated Gross Profit %',
        field: 'estimatedGrossProfitPercent',
        sortable: true,
        searchable: true,
        searchField: 'estimatedGrossProfitPercent',
        exportField: 'estimatedGrossProfitPercent',
        sticky: false,
        visible: true,
      },
      {
        id: '27',
        label: 'Estimated Gross Profit',
        field: 'expectedGp',
        sortable: true,
        searchable: true,
        searchField: 'expectedGp',
        exportField: 'expectedGp',
        sticky: false,
        visible: false,
      },
      {
        id: '28',
        label: 'Est Booking Date',
        field: 'estBookingDate',
        sortable: true,
        searchable: true,
        searchField: 'estBookingDate',
        exportField: 'estBookingDate',
        sticky: false,
        visible: true,
      },
      {
        id: '29',
        label: 'Bid Due Date',
        field: 'bidDueDate',
        sortable: true,
        searchable: true,
        searchField: 'bidDueDate',
        exportField: 'bidDueDate',
        sticky: false,
        visible: false,
      },
      {
        id: '30',
        label: 'Est Install Date',
        field: 'estInstallDate',
        sortable: true,
        searchable: true,
        searchField: 'estInstallDate',
        exportField: 'estInstallDate',
        sticky: false,
        visible: false,
      },
      {
        id: '31',
        label: 'Est Invoice Date',
        field: 'estInvoiceDate',
        sortable: true,
        searchable: true,
        searchField: 'estInvoiceDate',
        exportField: 'estInvoiceDate',
        sticky: false,
        visible: false,
      },
      {
        id: '15',
        label: 'custom_field_1',
        field: 'customField1',
        sortable: true,
        searchable: true,
        searchField: 'customField1',
        exportField: 'customField1',
        sticky: false,
        visible: false,
      },
      {
        id: '16',
        label: 'custom_field_2',
        field: 'customField2',
        sortable: true,
        searchable: true,
        searchField: 'customField2',
        exportField: 'customField2',
        sticky: false,
        visible: false,
      },
      {
        id: '17',
        label: 'custom_field_3',
        field: 'customField3',
        sortable: true,
        searchable: true,
        searchField: 'customField3',
        exportField: 'customField3',
        sticky: false,
        visible: false,
      },
      {
        id: '18',
        label: 'custom_field_4',
        field: 'customField4',
        sortable: true,
        searchable: true,
        searchField: 'customField4',
        exportField: 'customField4',
        sticky: false,
        visible: false,
      },
      {
        id: '19',
        label: 'custom_field_5',
        field: 'customField5',
        sortable: true,
        searchable: true,
        searchField: 'customField5',
        exportField: 'customField5',
        sticky: false,
        visible: false,
      },
      {
        id: '20',
        label: 'custom_field_6',
        field: 'customField6',
        sortable: true,
        searchable: true,
        searchField: 'customField6',
        exportField: 'customField6',
        sticky: false,
        visible: false,
      },
      {
        id: '21',
        label: 'custom_field_7',
        field: 'customField7',
        sortable: true,
        searchable: true,
        searchField: 'customField7',
        exportField: 'customField7',
        sticky: false,
        visible: false,
      },
      {
        id: '22',
        label: 'custom_field_8',
        field: 'customField8',
        sortable: true,
        searchable: true,
        searchField: 'customField8',
        exportField: 'customField8',
        sticky: false,
        visible: false,
      },
      {
        id: '23',
        label: 'custom_field_9',
        field: 'customField9',
        sortable: true,
        searchable: true,
        searchField: 'customField9',
        exportField: 'customField9',
        sticky: false,
        visible: false,
      },
      {
        id: '24',
        label: 'custom_field_10',
        field: 'customField10',
        sortable: true,
        searchable: true,
        searchField: 'customField10',
        exportField: 'customField10',
        sticky: false,
        visible: false,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

    colorCoding: {
      ESTBOOKINGDATE: {
        dateField: 'ESTBOOKINGDATE',
        currentDateColor: '#ff6600',
        withinDateColor: '#fbff00',
        pastDateColor: '#d22222',
        days: 3,
      },
    },
  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getDrilldownGrid',
      // leads: 'DataGrids/getLeadsDrilldown',
      lead: 'Leads/getLead',
      loading: 'DataGrids/getDrilldownLoading',
      leadSources: 'Lookups/getLeadSources',
      leadStages: 'Lookups/getLeadStatuses',
      temperatures: 'Lookups/getTemperatures',
      probabilities: 'Lookups/getProbabilities',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',
      dateColorCoding: 'Lookups/getDateColorConfigs',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    body() {
      return this.checkedRows.length >= 1 ? this.checkedRows : this.leads;
    },

    required() {
      if ((this.stageId === 145 || this.stageId === 146)
      && this.leadStages.length > 0) {
        const { code } = this.leadStages.filter((i) => i.id === this.stageId)[0];
        let stage;
        if (code === 'closed_converted') {
          stage = 'closed_won';
        } else if (code === 'closed_not_converted') {
          stage = 'closed_lost';
        } else {
          stage = code;
        }
        const result = this.leadStages.filter((i) => i.stageCode === stage);
        return result.length > 0 ? result[0].isRequired : false;
      }
      return false;
    },

    hasReasons() {
      if ((this.stageId === 145 || this.stageId === 146)
      && this.leadStages.length > 0) {
        const { code } = this.leadStages.filter((i) => i.id === this.stageId)[0];
        let stage;
        if (code === 'closed_converted') {
          stage = 'closed_won';
        } else if (code === 'closed_not_converted') {
          stage = 'closed_lost';
        } else {
          stage = code;
        }
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === stage);
        return result.length > 0;
      }
      return false;
    },

    leads: {
      get() {
        return this.$store.state.DataGrids.leadsDrilldown;
      },

      set(value) {
        return this.$store.commit('DataGrids/SET_LEADS_DRILLDOWN_CONTENT', value);
      },
    },

    owner: {
      get() {
        return this.$store.state.Leads.lead.owner;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_OWNER', value);
      },
    },

    account: {
      get() {
        return this.$store.state.Leads.lead.account;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ACCOUNT', value);
      },
    },

    primaryContact: {
      get() {
        return this.$store.state.Leads.lead.primaryContact;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT', value);
      },
    },

    stageId: {
      get() {
        return this.$store.state.Leads.lead.stageId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_STAGE_ID', value);
      },
    },

    temperatureId: {
      get() {
        return this.$store.state.Leads.lead.temperatureId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_TEMPERATURE_ID', value);
      },
    },

    leadsSourceId: {
      get() {
        return this.$store.state.Leads.lead.leadsSourceId;
      },
      set(value) {
        return this.$store.commit('Leads/SET_LEAD_LEADS_SOURCE_ID', value);
      },
    },

    estimatedRevenue: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.estimatedRevenue;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ESTIMATED_REVENUE', value);
      },
    },

    probability: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.probability;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_PROBABILITY', value);
      },
    },

    estimatedGrossProfit: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.estimatedGrossProfit;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ESTIMATED_GROSS_PROFIT', value);
      },
    },

    estGrossProfitPercentage: {
      get() {
        return parseFloat(this.$store.state.Leads.lead.estimatedPotentials
          .estGrossProfitPercentage);
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_EST_GROSS_PROFIT_PERCENTAGE', parseFloat(value, 10));
      },
    },

    bidDueDate: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.bidDueDate;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_BID_DUE_DATE', value);
      },
    },

    estBookingDate: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.estBookingDate;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ESTIMATED_EST_BOOKING_DATE', value);
      },
    },

    estInstallDate: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.estInstallDate;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_EST_INSTALL_DATE', value);
      },
    },

    estInvoiceDate: {
      get() {
        return this.$store.state.Leads.lead.estimatedPotentials.estInvoiceDate;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_EST_INVOICE_DATE', value);
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.leadOppCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Leads.lead.customFields;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', value);
      },
    },

    // Check if a user is an admin
    isAdmin() {
      return this.isUserAdmin();
    },

    // Check if a user is a sales manager
    isSalesManager() {
      return this.isUserSalesManager();
    },

    routeName() {
      return this.$route && this.$route.name ? this.$route.name : '';
    },
  },

  watch: {
    rowInEdit: {
      handler(value) {
        this.$store.commit('Leads/SET_LEAD_OBJECT', value);
      },
    },

    stageId: {
      async handler(value) {
        if (this.isEditing && (value === 145 || value === 146)) {
          this.$store.commit('Opportunities/CLEAR_OPPORTUNITY_STAGE_REASONS');
          if (this.opportunityReasonStages.length > 0) {
            const { code } = this.leadStages
              .filter((i) => i.id === value)[0];
            let newStage;
            if (code === 'closed_converted') {
              newStage = 'closed_won';
            } else if (code === 'closed_not_converted') {
              newStage = 'closed_lost';
            } else {
              newStage = code;
            }
            const stages = this.opportunityReasonStages.filter((i) => i.stageCode === newStage);
            if (stages.length > 0) {
              const { id } = stages[0];
              this.isLoading = true;
              await this.$store.dispatch('Opportunities/fetchOpportunityStageReasons', id);
              this.isLoading = false;
            }
          }
          if (this.opportunityClosingReasons.length > 0) {
            this.isleadClosingReasonModal = true;
            this.$store.commit('Leads/SET_LEAD_CLOSING_REASON', null);
            this.$refs.closingReasonField.resetValidator();
          }
        }
      },
    },

    perPage: {
      handler() {
        this.fetchLeads();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },

    selectedAccount(value) {
      if (value.accountId) {
        this.$store.commit('Leads/SET_LEAD_ACCOUNT_ID', value.accountId);
      }
    },

    selectedContact(value) {
      if (value.contactId) {
        this.$store.commit(
          'Leads/SET_LEAD_PRIMARY_CONTACT_ID',
          value.contactId,
        );
      }
    },

    selectedUser(value) {
      this.$store.commit('Leads/SET_LEAD_OWNER_ID', value.userId);
    },

    openSwitch: {
      handler() {
        this.fetchLeads();
      },
      deep: true,
      immediate: true,
    },

    $route: {
      handler() {
        this.filters = this.$route.query;
        this.fetchLeads();
      },
      deep: true,
    },

    isComponentModalActive: {
      handler(value) {
        if (!value) {
          this.fetchLeads();
        }
      },
      deep: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchDrilldownGrid', {
        gridName: 'leads_drilldown',
        organizationId,
      });
      await this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
      await this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');

      const sortedColumnList = [...this.columns]
        .map((newItem) => {
          if (newItem.label.startsWith('custom')) {
            return ({ ...newItem, visible: true });
          }
          return newItem;
        })
        .filter((item) => (item.label.startsWith('custom') ? this.getCustomFieldLabel(item.label, 'Lead_Opportunity') !== '' : true));
      this.columns = sortedColumnList;

      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createDrilldownGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'leads_drilldown',
          customizations: this.customizations,
        });
      }
      const colorResponse = await this.$store.dispatch(
        'Lookups/fetchDateColorConfig',
        {
          dateField: 'ESTBOOKINGDATE',
        },
      );
      if (colorResponse) {
        this.colorCoding = this.dateColorCoding;
      } else {
        await this.$store.dispatch(
          'Lookups/addDateColorConfig',
          this.colorCoding.ESTBOOKINGDATE,
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    try {
      this.isLoading = true;
      this.filters = { ...this.$route.query };
      if (this.openSwitch) {
        this.filters = { ...this.filters, status: 'open' };
      }
      this.fetchLeads();
      this.$store.dispatch('Lookups/fetchLeadSources');
      this.$store.dispatch('Lookups/fetchLeadStatuses');
      this.$store.dispatch('Lookups/fetchTemperatures');
      this.$store.dispatch('Lookups/fetchProbabilities');
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'ESTBOOKINGDATE',
      });
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'BIDDUEDATE',
      });
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'ESTINSTALLDATE',
      });
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'ESTINVOICEDATE',
      });

      if (this.dateColorCoding != null) {
        this.colorCoding = this.dateColorCoding;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  destroyed() {
    this.$store.dispatch('Grids/updateDrilldownGrid', {
      organizationid: localStorage.getItem('organization_id'),
      userid: localStorage.getItem('user_id'),
      columns: this.columns,
      name: 'leads_drilldown',
      customizations: this.customizations,
    });
    this.$store.commit('Leads/CLEAR_LEADS');
    this.$store.commit('Leads/CLEAR_LEAD');
  },

  methods: {
    async setMultiSelectFields() {
      if (this.selectedLeadId && this.isEditing) {
        const customFields = [...this.customFields];
        const newcustomFields = customFields.map((it) => ({
          ...it,
          value: Array.isArray(it.value)
            ? it.value.join(',') : it.value,
        }));

        const newValue = {
          ...this.lead,
          customFields: newcustomFields,
        };

        await this.setRowInEdit(newValue);
      }
    },

    async setFormattedMultiSelectValues(index, field) {
      try {
        if (this.selectedLeadId && this.isEditing) {
          this.leads = this.leads.content.map((item, ind) => {
            if (index === ind && this.inEditingMode) {
              const newItem = { ...item };
              if (item[field]) {
                newItem[field] = item[field] && Array.isArray(item[field]) ? item[field] : item[field].split(' ');
              }
              return newItem;
            }
            return item;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setNewEditedValues(event, label) {
      if (this.selectedLeadId && this.isEditing) {
        const customFields = [...this.customFields];
        const findField = customFields.findIndex((obj) => obj.label === label);
        const newObj = customFields[findField];
        customFields.splice(findField, 1, {
          ...newObj,
          value: Array.isArray(event)
            ? event.join(',') : event,
        });

        const newValue = {
          ...this.lead,
          customFields,
        };

        await this.setRowInEdit(newValue);
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    /**
     * setGrossProfit
     *
     */
    setGrossProfit() {
      if (this.estimatedRevenue > 0) {
        const estimatedGrossProfit = (this.estimatedRevenue / 100) * this.estGrossProfitPercentage;
        this.$store.commit(
          'Leads/SET_LEAD_ESTIMATED_GROSS_PROFIT',
          estimatedGrossProfit,
        );
      }
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedLeadId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    today(date) {
      const day = new Date(date).setHours(0, 0, 0, 0);
      const now = new Date().setHours(0, 0, 0, 0);
      if (day === now) {
        return true;
      }
      return false;
    },

    pastDate(date) {
      const day = new Date(date).setHours(0, 0, 0, 0);
      const now = new Date().setHours(0, 0, 0, 0);
      if (day < now) {
        return true;
      }
      return false;
    },

    inFutureDays(date, days) {
      if (days === 0) {
        return false;
      }
      const day = new Date(date).setHours(0, 0, 0, 0);
      const now = new Date();
      // eslint-disable-next-line max-len
      const futureDate = new Date(
        now.getTime() + days * 24 * 60 * 60 * 1000,
      ).setHours(0, 0, 0, 0);
      if (now.getTime() < day && day <= futureDate) {
        return true;
      }
      return false;
    },

    dateColor(date, futureDays, dateType) {
      let result = '';

      if (this.today(date)) {
        result = this.colorCoding[dateType].currentDateColor;
      } else if (this.pastDate(date)) {
        result = this.colorCoding[dateType].pastDateColor;
      } else if (this.inFutureDays(date, futureDays)) {
        result = this.colorCoding[dateType].withinDateColor;
      } else {
        result = 'white';
      }

      return result;
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );

      const getVisibility = (fieldName) => {
        const column = this.grid.columns.find((el) => el.label === fieldName);
        if (column) {
          return column.visible;
        }
        return true;
      };

      const newColumns = this.columns.map((el) => ({
        ...el,
        visible: getVisibility(el.label),
      }));

      const customFieldExists = (fieldName) => {
        const data = JSON.parse(localStorage.getItem('custom_fields'));
        const customFields = [...data];
        return customFields.includes((el) => el.code === fieldName && (el.objectName === 'Lead_Opportunity'));
      };

      const hasCustomField = (fieldName) => newColumns.includes(
        (el) => el.label === fieldName,
      );

      // check for customFields available & append
      if (!hasCustomField('custom_field_1') && customFieldExists('custom_field_1')) {
        newColumns.push(
          {
            id: '15',
            label: 'custom_field_1',
            field: 'customField1',
            sortable: true,
            searchable: true,
            searchField: 'customField1',
            exportField: 'customField1',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_2') && customFieldExists('custom_field_2')) {
        newColumns.push(
          {
            id: '16',
            label: 'custom_field_2',
            field: 'customField2',
            sortable: true,
            searchable: true,
            searchField: 'customField2',
            exportField: 'customField2',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_3') && customFieldExists('custom_field_3')) {
        newColumns.push(
          {
            id: '17',
            label: 'custom_field_3',
            field: 'customField3',
            sortable: true,
            searchable: true,
            searchField: 'customField3',
            exportField: 'customField3',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_4') && customFieldExists('custom_field_4')) {
        newColumns.push(
          {
            id: '18',
            label: 'custom_field_4',
            field: 'customField4',
            sortable: true,
            searchable: true,
            searchField: 'customField4',
            exportField: 'customField4',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_5') && customFieldExists('custom_field_5')) {
        newColumns.push(
          {
            id: '19',
            label: 'custom_field_5',
            field: 'customField5',
            sortable: true,
            searchable: true,
            searchField: 'customField5',
            exportField: 'customField5',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_6') && customFieldExists('custom_field_6')) {
        newColumns.push(
          {
            id: '20',
            label: 'custom_field_6',
            field: 'customField6',
            sortable: true,
            searchable: true,
            searchField: 'customField6',
            exportField: 'customField6',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_7') && customFieldExists('custom_field_7')) {
        newColumns.push(
          {
            id: '21',
            label: 'custom_field_7',
            field: 'customField7',
            sortable: true,
            searchable: true,
            searchField: 'customField7',
            exportField: 'customField7',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_8') && customFieldExists('custom_field_8')) {
        newColumns.push(
          {
            id: '22',
            label: 'custom_field_8',
            field: 'customField8',
            sortable: true,
            searchable: true,
            searchField: 'customField8',
            exportField: 'customField8',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_9') && customFieldExists('custom_field_9')) {
        newColumns.push(
          {
            id: '23',
            label: 'custom_field_9',
            field: 'customField9',
            sortable: true,
            searchable: true,
            searchField: 'customField9',
            exportField: 'customField9',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_10') && customFieldExists('custom_field_10')) {
        newColumns.push(
          {
            id: '24',
            label: 'custom_field_10',
            field: 'customField10',
            sortable: true,
            searchable: true,
            searchField: 'customField10',
            exportField: 'customField10',
            sticky: false,
            visible: true,
          },
        );
      } else if (hasCustomField('custom_field_1') && !customFieldExists('custom_field_1')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_1');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_2') && !customFieldExists('custom_field_2')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_2');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_3') && !customFieldExists('custom_field_3')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_3');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_4') && !customFieldExists('custom_field_4')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_4');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_5') && !customFieldExists('custom_field_5')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_5');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_6') && !customFieldExists('custom_field_6')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_6');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_7') && !customFieldExists('custom_field_7')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_7');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_8') && !customFieldExists('custom_field_8')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_8');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_9') && !customFieldExists('custom_field_9')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_9');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_10') && !customFieldExists('custom_field_10')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_10');
        newColumns.splice(index, 1);
      }
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * fetchActivities
     *
     * @param {Number} value
     */
    async fetchActivities(value) {
      try {
        this.defaultOpenedDetails = [value];
        await this.$store.dispatch('Activities/fetchEmbeddedLeadActivities', {
          leadId: value,
          page: 0,
          perPage: 5,
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * addActivity
     *
     * @param {Number} leadId
     * @param {String} lead
     * @param {Number} typeId
     *
     */
    addActivity(leadId, lead, typeId) {
      this.$router.push(
        `/activities/add?leadId=${leadId}&lead=${lead}&typeId=${typeId}`,
      );
      this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_TYPE_ID', typeId);
    },

    async fetchLeads() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
        if (filters.status && filters.status.includes('Open - Contacted')) {
          filters.status = 'Open - Cont';
        } else if (filters.status && filters.status.includes('Open - Not Contacted')) {
          filters.status = 'Open - Not';
        } else if (filters.status && filters.status.includes('Closed - Converted')) {
          filters.status = 'Closed - Con';
        } else if (filters.status && filters.status.includes('Closed - Not Converted')) {
          filters.status = 'Closed - Not';
        } else {
          filters.status = '';
        }
      }

      if (filters.status.includes('Open - ')) {
        filters = { ...filters, status: filters.status };
      } else if (filters.status.includes('Closed - ')) {
        filters = { ...filters, status: filters.status };
      } else if (this.openSwitch) {
        filters = { ...filters, status: 'open' };
      } else {
        filters = { ...filters, status: '' };
      }
      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, primaryContactId: this.$route.params.contactId };
      }
      if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('DataGrids/fetchLeadsDrilldown', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.leads.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    async exportLeads() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
      }

      if (this.openSwitch) {
        filters = { ...filters, status: 'open' };
      } else {
        filters = { ...filters, status: '' };
      }
      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, primaryContactId: this.$route.params.contactId };
      }
      if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('Exports/fetchLeads', {
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @params { Number } - next page number
     */
    onPageChange(page) {
      this.page = page;
      this.from += this.perPage;
      this.fetchLeads();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchLeads();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = filters;
      this.fetchLeads();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.leadsTable.resetMultiSorting();
      this.$refs.leadsTable.filters = {};
      this.searchClause = '';
      this.filters = {};
      this.fetchLeads();
    },

    /**
     * onEdit
     *
     * @param {object} lead
     */
    async onEdit(lead, props) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Leads/fetchLead', lead.id);
        this.selectedLeadId = lead.id;
        this.isEditing = true;

        const newValue = {
          ...this.lead,
          customFields: this.customFieldsList.map((item) => {
            const sortedArr = this.lead.customFields
              .filter((newItem) => newItem.customFieldId === item.id);
            if (sortedArr.length > 0) {
              return (
                {
                  customFieldId: item.id,
                  customFieldEntryId: sortedArr.length > 0
                    ? sortedArr[0].customFieldEntryId : null,
                  label: item.label,
                  value: sortedArr[0].value && sortedArr[0].value.split(',').length > 1
                    ? sortedArr[0].value.split(',') : sortedArr[0].value,
                }
              );
            }
            return (
              {
                customFieldId: item.id,
                customFieldEntryId: null,
                label: item.label,
                value: null,
              }
            );
          }),
        };
        await this.setRowInEdit(newValue);

        this.leads = this.leads.content.map((item, ind) => {
          if (props.index === ind) {
            const objArr = Object.entries(item).map((i) => {
              if (i[0].startsWith('customField') && i[1] !== null) {
                return (
                  [i[0], i[1].split(',').length > 1 ? i[1].split(',') : i[1]]
                );
              }
              return (i);
            });
            return (
              {
                ...Object.fromEntries(objArr),
              }
            );
          }
          return item;
        });

        this.setMultiSelectFields();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.inEditingMode = true;
        this.$emit('finished-mapping');
      }
    },

    setRowInEdit(value) {
      this.rowInEdit = value;
      return this.rowInEdit;
    },

    /**
     * searchLead
     */
    searchLead(value) {
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchLeads();
      }, 800);
    },

    /**
     * sendMail
     *
     * @param {String} email
     */
    sendMail(email, subject) {
      window.location.assign(`mailto:${email}?subject=${subject}`);
    },

    /**
     * updateLead
     *
     */
    async updateLead() {
      const payload = { ...this.lead };
      if (this.required && this.closingReason === null) {
        this.isleadClosingReasonModal = true;
      } else {
        try {
          await this.$store.dispatch('Leads/updateLead', {
            leadId: this.selectedLeadId,
            lead: payload,
          });
          await this.fetchLeads();
          this.isEditing = false;
          this.selectedLeadId = '';
          this.$store.commit('Leads/CLEAR_LEAD');
        } catch (error) {
          console.error(error);
        }
      }
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        await this.fetchLeads();
        this.isEditing = false;
        this.selectedLeadId = '';
        this.$store.commit('Leads/CLEAR_LEAD');
      } catch (error) {
        console.error(error);
      }
    },

    openModal() {
      this.isComponentModalActive = true;
      const checkedLeads = this.checkedRows.length > 0 ? this.checkedRows : [];
      const leadIDList = checkedLeads.map((lead) => lead.id);
      this.formProps.dataIDList = leadIDList;
    },
  },
};
</script>

<style lang="css" scoped>
.modal-btn {
  margin: 0 0.5em;
}
</style>
