<template>
  <validation-observer
    ref="leadsForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(createLead)">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <!-- page header component -->
      <page-header
        name="add lead"
        icon="star"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid || accountId == ''"
                type="is-success"
                icon-left="save"
                native-type="submit"
              >
                create lead
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-three-quarters">
              <leads-form
                :lead-stages="leadStatuses"
                :temperatures="temperatures"
                :industries="industries"
                :lead-sources="leadSources"
                :no-of-employees="noOfEmployees"
              />
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid || accountId == ''"
              type="is-success"
              icon-left="save"
              value="create lead"
              native-type="submit"
            >
              create lead
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import {
  LeadsForm,
} from '@/components/Leads';
import { PageHeader } from '@/components/Shared';

import LeadsMixin from '@/mixins/LeadsMixin';

export default {
  name: 'AddLead',

  components: {
    ValidationObserver,
    LeadsForm,
    PageHeader,
  },

  mixins: [LeadsMixin],

  computed: {
    accountId: {
      get() {
        return this.$store.state.Leads.lead.accountId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ACCOUNT_ID', value);
      },
    },

    primaryContactId: {
      get() {
        return this.$store.state.Leads.lead.primaryContactId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT_ID', value);
      },
    },

  },

  beforeCreate() {
    this.$store.commit('Leads/CLEAR_LEAD_VIEW');
    this.$store.commit('Leads/CLEAR_LEAD');
  },

  methods: {
    async createLead() {
      this.isLoading = true;
      const payload = { ...this.lead };
      payload.leadTypes = [...payload.leadTypes].map((i) => ({
        lookupId: i,
      }));

      if (Object.keys(payload).includes('coOwner')) {
        if (payload.coOwner.id === (null || '')) {
          delete payload.coOwner;
        } else {
          payload.coOwner = {
            ownerId: payload.coOwner.id,
            percentage: payload.coOwner.percentage,
          };
        }
      }

      if (Object.keys(payload).includes('owner')) {
        if (payload.owner.id === '') {
          payload.owner = null;
        } else {
          payload.owner = {
            ownerId: payload.owner.id,
            percentage: payload.owner.percentage,
          };
        }
      }

      try {
        const response = await this.$store.dispatch('Leads/createLead', payload);
        if (this.$route.query.accountId) {
          await this.$router.push(`/accounts/${this.$route.query.accountId}/view?activeTab=2`);
        }
        await this.$router.push(`/leads/${response.id}/view`);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>

</style>
